<template>
  <div>
    <!-- Button -->
    <div class="top-table-container">
      <el-input v-model="search" prefix-icon="el-icon-search" class="search" placeholder="Buscar por palabra clave..." />
    </div>
    <!-- Table -->
    <el-table empty-text="Sin Resultados" ref="filterTable" :data="cotizacionesData" border style="width: 100%">
      <el-table-column type="expand">
        <template class="cabecera" slot-scope="props">
          <el-row>
            <el-col :span="12"><div class="grid-content bg-purple">Giro: {{ props.row.giro }}</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple">Email: {{ props.row.email }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="12"><div class="grid-content bg-purple-light">Dirección Facturación: {{ props.row.direccion_facturacion }}</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple">Ubicación Obra: {{ props.row.ubicacion_obra }}</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="12"><div class="grid-content bg-purple-light">Comuna: {{ props.row.comuna }}</div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple-light">Region: {{ props.row.region }}</div></el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column prop="id" width="50" label="N°" align="center"> </el-table-column>
      <el-table-column prop="created_at" width="100" label="Fecha" align="center"></el-table-column>
      <el-table-column prop="rut" width="100" label="RUT" sortable></el-table-column>
      <el-table-column prop="nombre" label="Cliente" sortable></el-table-column>
      <el-table-column prop="telefono" align="center" label="Telefono" width="120"></el-table-column>
      <el-table-column
        align="center"
        :filters="[{ text: 'Recibido', value: 'Recibido' }, { text: 'Finalizado', value: 'Finalizado' }]"
        :filter-method="filterTag"
        filter-placement="bottom-end"
        prop="estado"
        label="Estado"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag :type="scope.row.estado == 'Recibido' ? 'success' : 'brand'" disable-tranSitions>
            {{scope.row.estado}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Acciones" width="270" align="center">
        <template slot-scope="scope">
          <el-button size="mini" icon="el-icon-view" @click.prevent="formDetalle(scope.row)">Det.</el-button>
          <el-button size="mini" icon="el-icon-s-comment" @click.prevent="formcomentar(scope.row)">Com.</el-button>
          <el-button size="mini" icon="el-icon-s-comment" @click.prevent="formcomentar(scope.row)">PDF</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginate">
      <el-pagination background layout="prev, pager, next" @current-change="paginate" :page-size="pageSize" :total="total"> </el-pagination>
    </div>

    <!-- Modal Detalles -->
    <el-dialog :title="modalDetalleTitle" :visible.sync="dialogDetalleVisible" width="80%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <h6>Productos Cotizados</h6>
      <div class="block">
        <div v-for="(item,index) of productos" :key="index">
          <i class="el-icon-caret-right"></i> 
          {{item.producto}} : ${{item.total}} 
        </div>
      </div>
      <br>
      <br>
      <h6>Comentarios</h6>
      <div class="block">
        <el-timeline lock-scroll="false">
          <el-timeline-item v-for="(item,index) of comentarios" :key="index" :timestamp="item.timestamp" placement="top">
            <el-card>
              <p>{{ item.comentario }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>

    <!-- Modal => Comentarios -->
    <el-dialog :title="modalComentarioTitle" :visible.sync="dialogComentarVisible" width="60%" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-form :model="form">
        <el-form-item>
          <el-select v-model="estado" width="40%" placeholder="Estado">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="Ingrese Comentario"
            v-model="comentario">
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogComentarVisible = false">Cancelar</el-button>
        <el-button type="primary" @click="crearComentario">Guardar</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "@/axios";
import moment from "moment"


export default {
  name: "Cotizaciones",
  data() {
    return {
      cotizacionesData: '',
      modal: false,
      search: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tituloModal: "",
      dialogComentarVisible: false,
      dialogDetalleVisible: false,
      form: {},
      modalComentarioTitle: '',
      num_cotizacion: '',
      comentario: '',
      modalDetalleTitle: '',
      comentarios: [],
      productos: [],
      options: [{
          value: 'Recibido',
          label: 'Recibido'
        }, {
          value: 'Option2',
          label: 'Option2'
        }, {
          value: 'Option3',
          label: 'Option3'
        }, {
          value: 'Completada',
          label: 'Option4'
        }, {
          value: 'Finalizado',
          label: 'Finalizado'
      }],
      estado: '',
      activeNames: ['0']
    };
  },
  created() {
    this.mostrar();
  },
  computed: {
    searching() {
      if (!this.search) {
        this.total = this.cotizacionesData.length;
        return this.cotizacionesData;
      }
      this.page = 1;
      return this.cotizacionesData.filter(data => data.nombre.toLowerCase().includes(this.search.toLowerCase()));
    },
  },
  methods: {
    // MÉTODOS PARA EL CRUD
    mostrar() {
      axios
        .get("cotizacion")
        .then(res => {
          console.log(res.data.cabecera);
          this.cotizacionesData = res.data.cabecera.map(element => {
            let fechaCot = element.created_at.substring(0,10)
            return {
              comuna: element.comuna,
              created_at: fechaCot.split('-').reverse().join('-'),
              direccion_facturacion: element.direccion_facturacion,
              email: element.email,
              estado: element.estado,
              giro: element.giro,
              id: element.id,
              metros: element.metros,
              nombre: element.nombre,
              region: element.region,
              rut: element.rut,
              telefono: element.telefono,
              ubicacion_obra: element.ubicacion_obra
            }
          })
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido mostrar la data, ah ocurrido un error inesperado`,
          });
        });
    },
    // Paginación
    paginate(val) {
      this.page = val;
    },
    // Comentar
    formcomentar(val) {
      this.value = 'Option3'
      this.num_cotizacion = val.id
      this.dialogComentarVisible = true
      this.modalComentarioTitle = '[Nombre Usuario] Fecha:' + moment().format('DD/MM/YYYY');
    },
    crearComentario() {

      let comentariotemp = ''
      console.log(this.cotizacionesData[0].estado,this.estado);
      if (this.cotizacionesData[0].estado != this.estado) {
          comentariotemp = 'Cambia Estado ' + this.cotizacionesData[0].estado + ' a '+ this.estado + '. ' + this.comentario
      } else {
        comentariotemp = this.comentario
      }

      const datos = {
        num_cotizacion_id: this.num_cotizacion,
        user_id: '1',
        estado: this.estado,
        fecha_comentario: moment().format('YYYY-MM-DD'),
        comentario: comentariotemp
      }
      axios
        .post("cotizacion", datos)
        .then(res => {
          if (res) {
            this.loading = true;
            setTimeout(() => {
              this.loading = false;
              this.$notify({
                title: "Éxito",
                dangerouslyUseHTMLString: true,
                message: `Comentario ha sido creado con exito!`,
                type: "success",
              });
              this.dialogComentarVisible = false
              this.mostrar();
            }, 1500);
          }
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido crear el comentario, ah ocurrido un error inesperado`,
          });
        });
    },
    formDetalle(val) {
      console.log(val);
      this.num_cotizacion = val.id
      this.dialogDetalleVisible = true
      this.modalDetalleTitle = 'Cotizacion N°: ' + this.num_cotizacion
      this.mostrarComentarios(val.id)
    },
    mostrarComentarios(id) {
      axios
        .get(`cotizacion/${id}`)
        .then(res => {
          this.productos = res.data.detalle.map(element =>{
            return {
              id: element.producto,
              producto: element.nombre,
              multiplicable: element.multiplicable,
              total: element.precio_total
            }
          })
          this.comentarios = res.data.comentarios.map(element => {
            let fecha = moment(element.created_at).format('DD-MM-YYYY HH:mm');
            return {
              timestamp: fecha,
              comentario: element.comentario
            }
          })
        })
        .catch(err => {
          console.log(err);
          this.$notify.error({
            title: "Error",
            dangerouslyUseHTMLString: true,
            message: `No se ha podido mostrar la data, ah ocurrido un error inesperado`,
          });
        });
    },
    resetDateFilter() {
      this.$refs.filterTable.clearFilter('date');
    },
    clearFilter() {
      this.$refs.filterTable.clearFilter();
    },
    formatter(row, column) {
      return row.address;
    },
    filterTag(value, row) {
      return row.estado === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    handleChange() {},
  },
};
</script>
